<template>
	<div v-loading='loading'>
		
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" style="margin-bottom:100px" class="demo-ruleForm">
			<div style="background: #fff;padding:0 0 15px;margin-top: 10px;" v-for="(item, index) in ruleForm.modeConfig" :key="index">
				<div style="background: #DCDFE6;padding: 10px 15px;margin-bottom: 15px;">级别{{ numberFormat[index] }}
					{{item.AgentRoleGradeNum == 1 ? '（最高级别）' :((index==ruleForm.modeConfig.length-1)?'（最低级别）':'')}}
				</div>
				<el-form-item label="级别名称" prop="AgentRoleName" :rules="rules.AgentRoleName" :prop="'modeConfig.'+index+'.AgentRoleName'">
					<el-input v-model="item.AgentRoleName" placeholder="最多输入10个字" size="small" style="width: 400px;"></el-input>
				</el-form-item>
				<el-form-item :label="'允许升级到级别'+numberFormat[index-1]" v-if="index>0">
					<el-checkbox :label="true" v-model="item.IsAllowUp" @change="(e)=>{changeCondition(e,index)}">开启</el-checkbox>
				</el-form-item>
				<div v-if='((index!=ruleForm.modeConfig.length-1)||item.AgentRoleGradeNum==1)&&item.showCondition'>
					<el-form-item label="升级条件" :rules="rules.nocheck">
						<!-- <label style="margin-left:24px;"><span style="color: red;margin-right: 2px;">*</span><span style="color:#606266;">升级条件</span></label> -->
						<div style="display: inline-block;">
							<el-checkbox v-model="item.UpToCurrentIsOpenPersonalOrderCount" style="margin: 0 15px;"></el-checkbox>
							<el-form-item class="singleOrderCountStyle" style="display: inline-block;" :rules="item.UpToCurrentIsOpenPersonalOrderCount?rules.UpToCurrentPersonalOrderCount:rules.nocheck"
							:prop="'modeConfig.' + index + '.UpToCurrentPersonalOrderCount'">
								推手销售 <el-input v-model="item.UpToCurrentPersonalOrderCount " @input='onlyclustNum(item.UpToCurrentPersonalOrderCount ,index,"UpToCurrentPersonalOrderCount",1)'
								style="width: 200px;margin: 0 10px;" key="UpToCurrentPersonalOrderCount" @blur='blurNum(item.UpToCurrentPersonalOrderCount ,index,"UpToCurrentPersonalOrderCount",1)'></el-input>
								单
							</el-form-item>
						</div>
						<div style="margin-top: 25px;">
							<el-checkbox v-model="item.UpToCurrentIsOpenPersonalSell" style="margin: 0 15px;"></el-checkbox>
							<el-form-item class="aUpToCurrentPersonalSellStyle" style="display: inline-block;" :rules="item.UpToCurrentIsOpenPersonalSell?rules.UpToCurrentPersonalSell:rules.nocheck"
							:prop="'modeConfig.' + index + '.UpToCurrentPersonalSell'">
								推手销售额满 <el-input v-model="item.UpToCurrentPersonalSell" @input='onlyclustNum(item.UpToCurrentPersonalSell,index,"UpToCurrentPersonalSell",2)'
								style="width: 200px;margin: 0 10px;" key="UpToCurrentPersonalSell" @blur='blurNum(item.UpToCurrentPersonalSell ,index,"UpToCurrentPersonalSell",2)'></el-input>
								元 
							</el-form-item>
						</div>
						<div style="margin-top: 25px;">
							<el-checkbox v-model="item.UpToCurrentIsOpenAllTeamOrderCount" style="margin: 0 15px;"></el-checkbox>
							<el-form-item class="allOrderCountStyle" style="display: inline-block;" :rules="item.UpToCurrentIsOpenAllTeamOrderCount?rules.UpToCurrentAllTeamOrderCount:rules.nocheck"
							:prop="'modeConfig.' + index + '.UpToCurrentAllTeamOrderCount'">
								推手团队 + 推手推荐团队销售达到 <el-input v-model="item.UpToCurrentAllTeamOrderCount" @input='onlyclustNum(item.UpToCurrentAllTeamOrderCount,index,"UpToCurrentAllTeamOrderCount",1)'
								style="width: 200px;margin: 0 10px;" key="UpToCurrentAllTeamOrderCount" @blur='blurNum(item.UpToCurrentAllTeamOrderCount ,index,"UpToCurrentAllTeamOrderCount",1)'></el-input>
								单
							</el-form-item>
						</div>
						<div style="margin-top: 25px;">
							<el-checkbox v-model="item.UpToCurrentIsOpenAllTeamSell" style="margin: 0 15px;"></el-checkbox>
							<el-form-item class="allOrderCountStyle" style="display: inline-block;" :rules="item.UpToCurrentIsOpenAllTeamSell?rules.UpToCurrentAllTeamSell:rules.nocheck"
							:prop="'modeConfig.' + index + '.UpToCurrentAllTeamSell'">
								推手团队 + 推手推荐团队销售额满 <el-input v-model="item.UpToCurrentAllTeamSell" @input='onlyclustNum(item.UpToCurrentAllTeamSell,index,"UpToCurrentAllTeamSell",2)'
								style="width: 200px;margin: 0 10px;" key="UpToCurrentAllTeamSell" @blur='blurNum(item.UpToCurrentAllTeamSell ,index,"UpToCurrentAllTeamSell",2)'></el-input>
								元
							</el-form-item>
						</div>
					</el-form-item>
					<div class="blueBox">
						<div>升级条件说明：</div>
						<div>1.推手销售订单：通过推手链接进入商城并支付成功的订单数量，包含推手自购订单</div>
						<div>2.推手团队销售订单：推手销售订单+推手团队下级销售订单</div>
						<div>3.推手推荐团队销售订单：推手一条线推荐的团队订单；推手A平级推荐推手B，推手B平级推荐推手C，推手A的推荐团队销售订单=推手B销售订单+推手B团队下级销售订单+推手C销售订单+推手C团队</div>
						<div>下级销售订单；以此类推</div>
						<div>4.销售额统计：即上述销售订单，订单实际支付金额，不包含运费；订单确认收货前产生的退款不计入（订单确认收货，若仍产生退款，不扣减升级所需销售额门槛）</div>
					</div>
				</div>
				<el-form-item label="加入条件" :rules="rules.nocheck" v-if="(index==ruleForm.modeConfig.length-1)&&item.AgentRoleGradeNum!=1">
					<!-- <div class="flex" v-if="(index==ruleForm.modeConfig.length-1)&&item.AgentRoleGradeNum!=1"> -->
						<!-- <label style="margin-left:24px;"><span style="color: red;margin-right: 2px;">*</span>
						<span style="color:#606266;">加入条件</span></label> -->
						<div v-if="result.DriverAuthThresholdType==0">
							<span style="margin-left:15px;margin-right: 15px;">普通客户升级到最低级别，需要购买代理授权礼包</span>
							<el-button type="text" @click='toPushgoodlist'>点击去配置授权礼包</el-button>
						</div>
						<div v-if="result.DriverAuthThresholdType==1">
							<span style="margin-left:15px;margin-right: 15px;">普通客户升级到最低级别，需要累计完成交易{{result.ThresholdAmount}}元</span>
						</div>
						<div v-if="result.DriverAuthThresholdType==2">
							<span style="margin-left:15px;margin-right: 15px;">普通客户升级到最低级别，仅需要授权绑定手机号</span>
						</div>
					<!-- </div> -->
				</el-form-item>


			</div>
			<el-button v-if="ruleForm.modeConfig.length<3" style="width: 200px;margin: 20px 0 100px;" type="primary" @click="addLevel">添加级别</el-button>
		</el-form>
		<!-- addLevel -->
		<div class="bottom-save-btn">
			<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
		</div>
	</div>
</template>
<script>
	import {
		AgentRoleList,
		SaveRoleconfig
	} from '@/api/TurnTomySelf.js';
	import {
		malldriverRecruitmentConfig,
	} from '@/api/sv3.0.0'
	export default {
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			};
			var AllTeamOrderCountCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请设置订单数量'));
				} else if (value < 1 || value > 9999999) {
					return callback(new Error('订单数量请设置在1~9999999之间'));
				} else {
					return callback();
				}
			}
			var PersonalOrderCountCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请设置订单数量'));
				} else if (value < 1 || value > 9999999) {
					return callback(new Error('订单数量请设置在1~9999999之间'));
				} else {
					return callback();
				}
			}
			var UpToCurrentPersonalSellCheck = (rule, value, callback) => {
				let two = /^\d+(\.\d{1,2})?$/
				// console.log(value)
				if (!value && value !== 0) {
					return callback(new Error('销售额请设置在0.01~9999999之间，2位小数'));
				} else if (value < 0.01 || value > 9999999 || !two.test(value)) {
					return callback(new Error('销售额请设置在0.01~9999999之间，2位小数'));
				} else {
					return callback();
				}
			}
			var UpToCurrentAllTeamSellCheck = (rule, value, callback) => {
				let two = /^\d+(\.\d{1,2})?$/
				if (!value && value !== 0) {
					return callback(new Error('销售额请设置在0.01~9999999之间，2位小数'));
				} else if (value < 0.01 || value > 9999999 || !two.test(value)) {
					return callback(new Error('销售额请设置在0.01~9999999之间，2位小数'));
				} else {
					return callback();
				}
			}
			return {
				saveLoading: false,
				loading: false,
				numberFormat: ['一', '二','三'],
				ruleForm: {
					modeConfig: [{
						AgentRoleName: '',
						UpToCurrentAllTeamOrderCount: '',
						UpToCurrentPersonalOrderCount: '',
						UpToCurrentIsOpenAllTeamOrderCount: false,
						UpToCurrentIsOpenPersonalOrderCount: false,
						AgentRoleGradeNum: 1,

						UpToCurrentIsOpenPersonalSell:false,
						UpToCurrentPersonalSell:'',
						UpToCurrentIsOpenAllTeamSell:false,
						UpToCurrentAllTeamSell:'',
						IsAllowUp:true,
						showCondition:true,
					}]
				},
				rules: {
					nocheck: [{
						validator: checkno,
						required:true,
						trigger: 'change'
					}],
					AgentRoleName: [{
						required: true,
						message: '请填写级别名称',
						trigger: 'blur'
					}, {
						min: 1,
						max: 10,
						message: '最多输入10个字',
						trigger: 'blur'
					}],
					UpToCurrentAllTeamOrderCount: [{
						required: true,
						validator: AllTeamOrderCountCheck,
						trigger: 'blur'
					}],
					UpToCurrentPersonalOrderCount: [{
						required: true,
						validator: PersonalOrderCountCheck,
						trigger: 'blur'
					}],
					UpToCurrentPersonalSell:[{
						required: true,
						validator: UpToCurrentPersonalSellCheck,
						trigger: 'blur'
					}],
					UpToCurrentAllTeamSell:[{
						required: true,
						validator: UpToCurrentAllTeamSellCheck,
						trigger: 'blur'
					}]
				},
				result:{}
			}
		},
		beforeMount() {
			this.getagentConfig()
			this.getconfig()
		},
		methods: {
			changeCondition(e,i){
				this.ruleForm.modeConfig[i-1].showCondition = e
			},
			async getconfig(){
				try{
					let res = await malldriverRecruitmentConfig()
					if(res.IsSuccess){
						this.result = res.Result
					}
				}finally{
					//
				}
			},
			addLevel() {
				let obj = {
					Id: 0,
					AgentRoleName: '',
					AgentRoleGradeNum: this.ruleForm.modeConfig.length + 1,
					IsAllowUp:true,
					showCondition:true,
				}
				this.ruleForm.modeConfig.push(obj)
				this.changeCondition(true,this.ruleForm.modeConfig.length - 1)
			},
			toPushgoodlist() {
				this.$router.push({
					path: '/goods/goodsList'
				});
			},
			async getagentConfig() {
				try {
					this.loading = true
					let result = await AgentRoleList({})
					if (result.IsSuccess) {
						this.ruleForm.modeConfig = result.Result.map((v,i)=>{
							if(i<result.Result.length-1){
								v.showCondition = result.Result[i+1].IsAllowUp
							}else{
								v.showCondition = false
							}
							return v
						})
					}
				} catch (err) {

				} finally {
					this.loading = false
				}
			},
			//限制输入框只能输入整数
			onlyclustNum(obj, index, key,type) {
				// console.log(obj)
				obj = obj.replace(/[^\.\d]/g, '');
				if(type==1){
					obj = obj.replace('.', '');
				}
				this.ruleForm.modeConfig[index][key] = obj
			},
			blurNum(obj, index, key,type){
				if(type==2){
					let two = /^\d+(\.\d{1,2})?$/
					// console.log(obj,!two.test(obj))
					if(obj<0.01||obj>9999999||!two.test(obj)){
						// this.$message.error('销售额请设置在0.01~9999999之间，最多2位小数')
						// return 
					}
				}
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.saveLoading = true
							// let item = this.ruleForm.modeConfig[0]
							// if(!item.UpToCurrentIsOpenAllTeamOrderCount&&!item.UpToCurrentIsOpenAllTeamSell&&
							// !item.UpToCurrentIsOpenPersonalOrderCount&&!item.UpToCurrentIsOpenPersonalSell){
							// 		this.$message({
							// 			showClose: true,
							// 			type: 'error',
							// 			message: '请选择升级条件'
							// 		})
							// 		return
							// }
							let ruleForm = JSON.parse(JSON.stringify(this.ruleForm))
							
							// this.ruleForm.modeConfig.map(item => {
							// 	item.AgentRoleName = item.AgentRoleName.replace(/(^\s*)|(\s*$)/g, "")
							// 	return item
							// })
							let numlist = ['UpToCurrentAllTeamOrderCount','UpToCurrentAllTeamSell','UpToCurrentPersonalOrderCount','UpToCurrentPersonalSell']
							
							for(let index in ruleForm.modeConfig){
								let item = ruleForm.modeConfig[index]
								let nextItem = ruleForm.modeConfig[index+1]
								if(nextItem&&String(nextItem.IsAllowUp)&&nextItem.IsAllowUp&&
									!item.UpToCurrentIsOpenAllTeamOrderCount&&!item.UpToCurrentIsOpenAllTeamSell&&
									!item.UpToCurrentIsOpenPersonalOrderCount&&!item.UpToCurrentIsOpenPersonalSell){
										this.$message.error('请选择升级条件')
										return 
								}else{
									numlist.map(v=>{
										item[v] = Number(item[v])
									})
								}
								item.AgentRoleName = item.AgentRoleName.replace(/(^\s*)|(\s*$)/g, "")
							}
							let data = {
								AgentRoleList: this.ruleForm.modeConfig
							}
							let result = await SaveRoleconfig(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getagentConfig()
								setTimeout(() => {
									this.saveLoading = false; //响应后延迟几秒回复正常；
								}, 2500)

							}


						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {

							this.saveLoading = false
						}


					} else {}
				});

			},
		}
	}
</script>
<style lang="less" scoped>
.flex{
	display: flex;
}
.blueBox{
	border: 1px solid #409EEF;
	padding: 15px 100px 15px 15px;
	margin: 50px 15px 15px 25px;
	div{
		font-size: 14px;
		color: #606266;
		line-height: 1.7;
	}
}
	.allOrderCountStyle {
		::v-deep .el-form-item__error {
			margin-left: 229px;
			width: 100%;
		}
	}
	.aUpToCurrentPersonalSellStyle{
		::v-deep .el-form-item__error {
			margin-left: 99px;
			width: 100%;
		}
	}

	.singleOrderCountStyle {
		::v-deep .el-form-item__error {
			margin-left: 75px;
			width: 100%;
		}
	}

	.bottom-save-btn {
		position: fixed;
		width: 100%;
		background: #fff;
		bottom: 0;
		text-align: center;
		padding: 20px 0;
		z-index: 100;
		box-shadow: 13px 1px 6px #999;
	}

	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
</style>
